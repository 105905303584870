.navbar-toggler {
    background-color: rgba(255, 255, 255, 0.1);
}

.top-row {
    height: 3.5rem;
    background-color: rgba(0,0,0,0.4);
}

.navbar-brand {
    font-size: 1.1rem;
}

.oi {
    width: 2rem;
    font-size: 1.1rem;
    vertical-align: text-top;
    top: -2px;
}

.nav-item {
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
}

    .nav-item:first-of-type {
        padding-top: 1rem;
    }

    .nav-item:last-of-type {
        padding-bottom: 1rem;
    }

    .nav-item a {
        color: #d7d7d7;
        border-radius: 4px;
        height: 3rem;
        display: flex;
        align-items: center;
        line-height: 3rem;
    }


.nav-item a:hover {
    background-color: rgba(255,255,255,0.1);
    color: white;
}

.activeTwo{
    background-color: rgba(255,255,255,0.1);
    color: white;
}
.active{
    background-color: rgba(255,255,255,0.1);
    color: white;
}



@media (min-width: 641px) {
    .navbar-toggler {
        display: none;
    }

    .collapse {
        /* Never collapse the sidebar for wide screens */
        display: block;
    }
}

/*TOOLTIPS*/

/*NavLink[title]:hover::after {
    content: attr(title);
    position: absolute;
    top: -100%;
    left: 0;
}*/

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: -1.6em;
    left: 100%;
    padding: 4px 4px 4px 8px;
    color: #222;
    white-space: nowrap;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
    background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}

.nav-item a{
    color: #d7d7d7;
    border-radius: 4px;
    height: 3rem;
    display: flex;
    align-items: center;
    line-height: 3rem;
    width: 100%;
}

.nav-item{
    padding-bottom: 0.5rem;
}
/* 
.page-sidebar, .page-logo{
    background-color: #0F3CD8 !important;
} */ .page-logo,
.page-sidebar {
    width: auto !important;
    min-width: 230px;
}

.nav-item-box {
    color: #d7d7d7;
    border-radius: 4px;
    height: 3rem;
    display: flex;
    align-items: center;
    line-height: 3rem;
    width: 100%;
    font-weight: 500;
    font-size: 0.8125rem;
    cursor: pointer;
}

.nav-item-box:hover{
    background-color: rgba(255,255,255,0.1);
    color: white;
}

.nav-item-logOut {
    z-index: 1300;
    position: absolute;
    bottom: 0px;
    background: inherit;
}
.account-navItems-overflow{
    overflow: auto;
    max-height: calc(100vh - 100px);
}